<template>
    <div style="background-color: var(--gray-white);" class="body1">

        <div>
            <div class="bd-callout bd-callout-default list">
                <div id="application" class="header-hero bg_cover d-lg-flex align-items-center">
                    <section style="position: relative; z-index: 500; margin-top: 10px;" class="feature-menu p0 pb-1">
                        <div class="icons-wrapper top-menu-items d-flex justify-content-between">
                            <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center"
                                style="flex-grow: 1;">
                                <router-link to="/my-account">
                                    <div class="icon-container">
                                        <img style="width: 40px; height: 40px;" src="/img/topNav/bonus.png"
                                            class="menu-icon-svg">
                                    </div>
                                    <span style="color: #6c6c6c;">Bonus</span>
                                </router-link>
                            </div>

                            <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center"
                                style="flex-grow: 1;">
                                <router-link to="/gifts">
                                    <div class="icon-container">
                                        <img style="width: 40px; height: 40px;" src="/img/topNav/promos.png"
                                            class="menu-icon-svg">
                                    </div>
                                    <span style="color: #6c6c6c;">Promotions</span>
                                </router-link>
                            </div>

                            <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center"
                                style="flex-grow: 1;">
                                <router-link to="/my-account">
                                    <div class="icon-container">
                                        <img style="width: 40px; height: 40px;" src="/img/topNav/deposit.png"
                                            class="menu-icon-svg">
                                    </div>
                                    <span style="color: #6c6c6c;">Deposit</span>
                                </router-link>
                            </div>

                            <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center"
                                style="flex-grow: 1;">
                                <router-link to="/mobile-app">
                                    <div class="icon-container">
                                        <img style="width: 40px; height: 40px;" src="/img/topNav/download.png"
                                            class="menu-icon-svg">
                                    </div>
                                    <span style="color: #6c6c6c;">Download</span>
                                </router-link>
                            </div>
                        </div>
                    </section>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-right">
                                <div class="header-hero-content">
                                    <br />
                                    <ul style="list-style: none;">
                                        <li class="mb-2" >
                                            <a href="/app/falconbet.apk" class="btn btn-sm"
                                                style="scroll-behavior: smooth; background-color: var(--yellow); color: var(--darkBackground);"
                                                @click.prevent="handleDownloadAndScroll">
                                                <img style="width: 15px; margin-bottom: 3px;"
                                                    src="/assets/images/playstore.png">
                                                Download APK
                                            </a>
                                        </li>
                                    </ul>
                                </div> <!-- header hero content -->
                            </div>

                            <div class="col-12 ">
                                <img src="/img/app/app1.png" alt="app" style="width: 37%;" data-wow-delay="0.5s">
                                <img src="/img/app/app2.png" alt="app" style="width: 53%;" data-wow-delay="0.5s">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bottom-navigation active-bets="0" page="login"></bottom-navigation>

    </div>

</template>

<script>

import BottomNavigation from "@/components/BottomNavigation";

export default {
    name: 'Mobile',
    components: {
        BottomNavigation,
    },
    data() {
        return {
            currentStep: 0,
            showModal: false
        };
    },
    methods: {
        handleDownloadAndScroll() {
            setTimeout(() => {
                window.location.href = '/app/falconbet.apk';
            }, 500);
        },
        scrollToHowTo() {
            document.getElementById('howto').scrollIntoView({ behavior: 'smooth' });
        },
        nextStep() {
            if (this.currentStep < 6) {
                this.currentStep++;
            }
        },
        prevStep() {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        },
    },
    mounted() {
        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent the default prompt
            event.preventDefault();
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
        });
    }

}
</script>

<style scoped>
.multi-step-card {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    height: 100%;
}

.step-card {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: opacity 0.3s ease;
}
</style>